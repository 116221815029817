import { micromark } from 'micromark'

class MissiveJS {
  constructor() {
    this.id = null
    this.data = {}
    this.state = {}
    this.settings = {}

    this.messageID = 0
    this.eventsQueue = []
    this.callbacks = {}
    this.messageCallbacks = {}
    this.actionCallbacks = []
    this.streamCallbacks = {}
    this.DOMNodes = {}

    this.handleMessage = this.handleMessage.bind(this)

    this.injectMetaTag({
      name: 'viewport',
      content: 'width=device-width,height=device-height,initial-scale=1,user-scalable=no',
    })

    this.on('change:css_variables', (css_variables) => {
      this.handleCSSVariablesChange(css_variables)
    })

    this.on('change:theme', (theme) => {
      theme = theme.split('-')[0]
      this.setDataAttribute('theme', theme)
    })

    this.on('change:layout', (layout) => {
      this.setDataAttribute('layout', layout)
    })

    this.on('set:settings', (settings) => {
      this.settings = settings || {}
    })

    this.addListeners()

    // Aliases
    this.fetchUsers = this.fetchUser.bind(this)
    this.fetchMessages = this.fetchMessage.bind(this)
    this.fetchLabels = this.fetchLabel.bind(this)
    this.fetchOrganizations = this.fetchOrganization.bind(this)
    this.fetchConversations = this.fetchConversation.bind(this)

    this.fetchIntegration = this.fetchIntegration.bind(this)
    this.insertHTML = this.insertHtml.bind(this)
  }

  addListeners() {
    document.addEventListener('click', this.handleClick, { capture: true })
  }

  handleClick = (e) => {
    let link

    if (e.target && (link = e.target.closest('a'))) {
      const { href, target } = link

      if (target == '_blank' || href.startsWith('mailto:') || href.startsWith('tel:')) {
        e.preventDefault()
        this.openURL(link.href)
      }
    }
  }

  init({ id, settings, state, rollbar }) {
    this.id = id

    if (rollbar && rollbar.person) {
      this.user = rollbar.person
    }

    if ('Rollbar' in window) {
      if (rollbar) {
        Rollbar.configure({ payload: rollbar })
      }

      fetch('/version')
        .then((res) => res.json())
        .then(({ version } = {}) => {
          Rollbar.configure({
            payload: {
              client: {
                javascript: {
                  code_version: version,
                  source_map_enabled: true,
                  guess_uncaught_frames: true,
                },
              },
            },
          })
        })
        .catch((err) => {})
    }

    if (state) {
      for (let k in state) {
        let v = state[k]
        this.trigger(`change:${k}`, v)
      }
    }

    if (settings) {
      this.trigger('set:settings', settings)
    }

    if (this.eventsQueue.length) {
      for (let e of this.eventsQueue) {
        this.sendMessage.apply(this, e)
      }
    }
  }

  injectSVGIcon(icon, def) {
    this.injectedSVGIcons || (this.injectedSVGIcons = {})

    if (this.injectedSVGIcons[icon]) return
    this.injectedSVGIcons[icon] = true

    let SVGNamespace = 'http://www.w3.org/2000/svg'

    this.SVGSymbols ||
      (this.SVGSymbols = (() => {
        let div = document.createElement('div')
        div.id = 'svgs'
        div.style.display = 'none'

        let svg = document.createElementNS(SVGNamespace, 'svg')
        svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')

        div.appendChild(svg)
        document.body.insertBefore(div, document.body.firstChild)

        return svg
      })())

    let { svg, ...attributes } = def || SVGS[icon] || {}
    if (!svg) return false

    let symbol = document.createElementNS(SVGNamespace, 'symbol')
    symbol.id = icon
    for (const k in attributes) {
      symbol.setAttribute(k, attributes[k])
    }
    symbol.innerHTML = svg

    this.SVGSymbols.appendChild(symbol)
    return true
  }

  injectMetaTag(attributes) {
    let head = document.querySelector('head')
    let meta = document.createElement('meta')

    for (let k in attributes) {
      let v = attributes[k]
      meta.setAttribute(k, v)
    }

    head.insertBefore(meta, head.firstChild)
  }

  reload() {
    this.sendMessage('reload')
  }

  handleCSSVariablesChange(css_variables) {
    for (let k in css_variables) {
      let v = css_variables[k]
      k = k.replace(/^--/, '--missive-')
      this.setCSSVariable(k, v)
    }
  }

  setCSSVariable(name, value) {
    let { documentElement } = document
    documentElement.style.setProperty(name, value)
  }

  setDataAttribute(name, value) {
    let { documentElement } = document
    documentElement.setAttribute(`data-${name}`, value)
  }

  enqueueEvent(e) {
    this.eventsQueue.push(e)
  }

  setSelection({ draftId, anchor, head }) {
    this.sendMessage('setSelection', { draftId, anchor, head })
  }

  setActions(actions) {
    this.actionCallbacks = []

    actions.forEach((action) => {
      let { callback } = action
      delete action.callback

      action.index = this.actionCallbacks.length
      action.contexts || (action.contexts = ACTION_CONTEXTS)

      this.actionCallbacks.push(callback)
    })

    this.sendMessage('setActions', actions)
  }

  setPermissions(permissions) {
    this.sendMessage('setPermissions', permissions)
  }

  setAsUtility() {
    this.isUtility = true
    this.sendMessage('setAsUtility', null)
  }

  on(event, callback, { unshift, retroactive, triggerWhenHidden } = {}) {
    if (triggerWhenHidden) {
      this.sendMessage('configureEvent', { event, triggerWhenHidden })
    }

    for (let e of event.split(/\s+/)) {
      let callbacks = this.callbacks[e] || (this.callbacks[e] = [])
      let pushOrUnshift = unshift ? 'unshift' : 'push'

      if (callbacks.indexOf(callback) === -1) {
        callbacks[pushOrUnshift](callback)
      }
    }

    if (retroactive && event.startsWith('change:')) {
      let key = event.replace(/^change:/, '')

      if (key in this.state) {
        callback(this.state[key])
      }
    }
  }

  onStream(streamId, callback) {
    let callbacks = this.streamCallbacks[streamId] || (this.streamCallbacks[streamId] = [])

    if (callbacks.indexOf(callback) === -1) {
      callbacks.push(callback)
    }

    this.sendMessage('setStream', streamId)
  }

  trigger(event, data) {
    if (event.startsWith('change:')) {
      let key = event.replace(/^change:/, '')
      this.state[key] = data
    }

    let callbacks = this.callbacks[event]
    if (!callbacks || !callbacks.length) {
      return
    }

    for (let callback of callbacks) {
      callback(data)
    }
  }

  async sync({ jsonRoot } = {}) {
    try {
      const response = await this.get()
      this.data = jsonRoot ? response[jsonRoot] : response
      this.trigger('sync:done')
    } catch (error) {}
  }

  // App actions
  search(text) {
    this.sendMessage('search', text)
  }

  navigate(options) {
    this.sendMessage('navigate', options)
  }

  alert(opts = {}) {
    if (opts.closeForm) {
      this.closeForm()
    }

    if (opts.app || this.isUtility) {
      return this.sendMessage('alert', opts)
    }

    let { title, message, note } = opts
    title || (title = 'Oops, something went wrong!')
    message || (message = '')
    note || (note = 'You may want to try again later or refresh the integration.')

    let alert = document.createElement('div')
    alert.className = 'alert columns-middle columns-center'

    let content = ''
    if (title) content += `<div class="row text-large">${title}</div>`
    if (message) content += `<div class="row text-c">${message}</div>`
    if (note) content += `<div class="row text-c text-small">${note}</div>`

    alert.innerHTML = `
      <div class="alert-box align-center">
        <div class="padding-large">
          ${content}
        </div>
        <div class="alert-buttons columns">
          <span class="alert-button column-grow padding" onClick="Missive.reload()">Refresh</span>
          <span class="alert-button alert-button--active column-grow padding" onClick="Missive.closeAlert()">Got it</span>
        </div>
      </div>
    `

    this.closeAlert()

    this.DOMNodes.alert = alert
    document.body.appendChild(alert)
  }

  closeAlert() {
    if (!this.DOMNodes.alert) return
    this.DOMNodes.alert.parentNode.removeChild(this.DOMNodes.alert)
    delete this.DOMNodes.alert
  }

  openSelf() {
    this.sendMessage('openSelf')
  }

  closeSelf() {
    this.sendMessage('closeSelf')
  }

  openForm(opts = {}) {
    let promise = new Promise((resolve, reject) => {
      let notesCallbacks = {}

      if (opts.notes) {
        opts.notes.forEach((note, i) => {
          if (!note.callback) return
          i += 1
          notesCallbacks[i] = note.callback
          note.callback = i
        })
      }

      this.sendMessage('form', opts, {
        promise: {
          resolve: (data) => {
            if ('fields' in data && 'note' in data) {
              if (data.note) {
                let callback = notesCallbacks[data.note]
                if (callback) callback(data.fields)
                return
              }

              if (data.fields) {
                resolve(data.fields)
                return
              }
            }

            resolve(data)
          },
          reject,
        },
      })
    })

    return promise
  }

  closeForm(opts) {
    return this.sendMessage('form', Object.assign({}, opts, { close: true }))
  }

  openURL(url) {
    this.sendMessage('openURL', url)
  }

  initiateCallback(url) {
    const data = { url }
    const promise = new Promise((resolve, reject) => {
      this.sendMessage('initiateCallback', data, {
        instanceEvent: true,
        promise: {
          resolve: (queryString) => {
            const params = new URLSearchParams(queryString)
            const object = Object.fromEntries(params)

            resolve(object)
          },
          reject,
        },
      })
    })

    return promise
  }

  openContextMenu(data = {}) {
    let callbacks = []
    if (!data.options) return

    data.options.forEach((option) => {
      let { callback } = option
      delete option.callback

      option.index = callbacks.length
      callbacks.push(callback)
    })

    this.sendMessage('openContextMenu', data, {
      promise: {
        resolve: (index) => {
          let callback = callbacks[index]
          if (callback) callback.call()
        },
      },
    })
  }

  // Conversation actions
  createConversation({ select, count } = {}) {
    this.sendMessage('new', { select, count })
  }

  comment(body, opts) {
    if (!body || !body.length) return

    if (opts) {
      opts.body = body
      this.sendMessage('commentWithMeta', opts)
    } else {
      this.sendMessage('comment', { body })
    }
  }

  createTask(body, completed = false) {
    if (!body || !body.length) return

    body = `[${completed ? 'x' : ''}] ${body}`
    this.comment(body)
  }

  addLabels(labelIds) {
    if (!labelIds) return
    this.sendMessage('addLabels', labelIds)
  }

  removeLabels(labelIds) {
    if (!labelIds) return
    this.sendMessage('removeLabels', labelIds)
  }

  close() {
    this.sendMessage('close')
  }

  reopen() {
    this.sendMessage('reopen')
  }

  assign(userIds) {
    this.sendMessage('assign', userIds)
  }

  addAssignees(userIds) {
    if (!userIds) return
    this.sendMessage('addAssignees', userIds)
  }

  removeAssignees(userIds) {
    if (!userIds) return
    this.sendMessage('removeAssignees', userIds)
  }

  archive() {
    this.sendMessage('archive')
  }

  trash() {
    this.sendMessage('trash')
  }

  moveToInbox() {
    this.sendMessage('moveToInbox')
  }

  setColor(color) {
    this.sendMessage('setColor', color)
  }

  setSubject(subject) {
    this.sendMessage('setSubject', subject)
  }

  // Composer actions
  compose(options) {
    this.sendMessage('compose', options)
  }

  composeInConversation(options) {
    this.sendMessage('composeInConversation', options)
  }

  async reply(options) {
    return new Promise((resolve, reject) => {
      this.sendMessage('reply', options, {
        promise: { resolve, reject },
      })
    })
  }

  forward(options) {
    this.sendMessage('forward', options)
  }

  isComposerAvailable({ draftId }) {
    const opts = { draftId }
    const promise = new Promise((resolve, reject) => {
      this.sendMessage('isComposerAvailable', opts, {
        promise: { resolve, reject },
      })
    })

    return promise
  }

  enableComposer({ draftId, deleteVirtualCursorId } = {}) {
    this.sendMessage('enableComposer', { draftId, deleteVirtualCursorId })
  }

  disableComposer({ draftId } = {}) {
    this.sendMessage('disableComposer', { draftId })
  }

  insertText(text, { draftId, newLine, isMarkdown, virtualCursor } = {}) {
    if (!text && !virtualCursor) return
    return this.insertContent({ text, draftId, newLine, isMarkdown, virtualCursor })
  }

  insertHtml(html, { draftId, newLine } = {}) {
    if (!html) return
    return this.insertContent({ html, draftId, newLine })
  }

  insertContent({ text, html, draftId, newLine, isMarkdown, virtualCursor } = {}) {
    if (!text && !html && !virtualCursor) return

    return new Promise((resolve, reject) => {
      this.sendMessage(
        'insertContent',
        { text, html, draftId, newLine, isMarkdown, virtualCursor },
        { promise: { resolve, reject } },
      )
    })
  }

  writeToClipboard(text) {
    if (!text) return
    this.sendMessage('writeToClipboard', text)
  }

  // Helpers
  now({ seconds } = {}) {
    let now = new Date()
    let ms = this.parseDate(now)

    if (seconds) {
      return Math.round(ms / 1000)
    }

    return ms
  }

  isToday(date) {
    let today = new Date().setHours(0, 0, 0, 0)
    let d = new Date(date).setHours(0, 0, 0, 0)

    return today == d
  }

  isTomorrow(date) {
    let tomorrowStarts = new Date().setHours(24, 0, 0, 0)
    let tomorrowEnds = new Date().setHours(48, 0, 0, 0)
    let d = this.parseDate(date)

    return d >= tomorrowStarts && d < tomorrowEnds
  }

  isYesterday(date) {
    let yesterdayStarts = new Date().setHours(-24, 0, 0, 0)
    let yesterdayEnds = new Date().setHours(0, 0, -1, 0)
    let d = this.parseDate(date)

    return d >= yesterdayStarts && d < yesterdayEnds
  }

  isPast(date) {
    let now = this.parseDate(new Date())
    let d = this.parseDate(date)

    return d < now
  }

  isInLessThan(date, { hours, minutes, seconds }) {
    hours || (hours = 0)
    minutes || (minutes = 0)
    seconds || (seconds = 0)

    let now = this.parseDate(new Date())
    let d = this.parseDate(date)

    let h = hours * 60 * 60 * 1000
    let m = minutes * 60 * 1000
    let s = seconds * 1000

    let ends = now + h + m + s
    return d >= now && d < ends
  }

  parseDate(date) {
    if (typeof date == 'string') {
      if (/\s/.test(date)) {
        date = date.replace(/-/g, '/')
      }
    }

    return Date.parse(date)
  }

  formatDate(string, options) {
    let timestamp = this.parseDate(string)
    return this.formatTimestamp(timestamp, options)
  }

  formatTimestamp(timestamp, options = {}) {
    timestamp = timestamp.toString()
    if (timestamp.length == 10) timestamp += '000'

    let date = new Date(parseInt(timestamp))
    let formatted = null

    if (options.today && this.isToday(date)) {
      formatted = 'Today'
    } else if (options.tomorrow && this.isTomorrow(date)) {
      formatted = 'Tomorrow'
    }

    if (!formatted) {
      let month = this.getMonth(date.getMonth(), { short: true })
      formatted = `${month} ${date.getDate()}`

      if (options.until) {
        let { until } = options

        until = until.toString()
        if (until.length == 10) until += '000'
        until = new Date(parseInt(until))

        let uMonth = this.getMonth(until.getMonth(), { short: true })
        let uFormatted = `${uMonth} ${until.getDate()}`

        if (formatted != uFormatted) {
          formatted += ` - ${uFormatted}`
        }
      }

      let currentYear = new Date().getFullYear()
      let dateYear = date.getFullYear()
      if (options.year || currentYear != dateYear) {
        formatted += `, ${dateYear}`
      }
    }

    if (options.time) {
      let hours = this.pad(date.getHours(), 2, '0')
      let minutes = this.pad(date.getMinutes(), 2, '0')
      formatted += ` at ${hours}:${minutes}`
    }

    return { date, formatted }
  }

  getMonth(i, { short } = {}) {
    let month = MONTHS[i]
    if (!month) return ''

    if (short) {
      return month.short
    }

    return month.long
  }

  getEmailAddresses(conversations) {
    let addresses = []
    let names = {}

    for (let conversation of conversations) {
      if (!conversation.email_addresses) continue

      for (let addressField of conversation.email_addresses) {
        let { address, name } = addressField
        if (!address) continue

        if (addresses.indexOf(address) == -1) {
          addresses.push(address)
        }

        names[address] || (names[address] = name || '')
      }
    }

    return addresses.map((a) => {
      return { address: a, name: names[a] }
    })
  }

  getPhoneNumbers(conversations) {
    let phoneNumbers = []
    let names = {}

    for (let conversation of conversations) {
      if (!conversation.phone_numbers) continue

      for (let phoneField of conversation.phone_numbers) {
        let { value, name } = phoneField
        if (!value) continue

        if (phoneNumbers.indexOf(value) == -1) {
          phoneNumbers.push(value)
        }

        names[value] || (names[value] = name || '')
      }
    }

    return phoneNumbers.map((a) => {
      return { phoneNumber: a, name: names[a] }
    })
  }

  formatContact({ name, address, phoneNumber }) {
    let letters = ''
    let formatted = ''

    if (name) {
      let nonAlphaChar = false

      letters = name
        .split(/\s+/)
        .map((s) => {
          if (nonAlphaChar) return
          let l = s[0]

          if (!l || !l.match(/[a-z]/i)) {
            nonAlphaChar = true
            return
          }

          return l
        })
        .filter(Boolean)
        .join('')
        .slice(0, 3)
        .toUpperCase()

      if (!letters) {
        return this.formatContact({ address })
      }

      if (address) {
        formatted = `${name} <${address}>`
      } else {
        formatted = name
      }
    } else {
      if (address) {
        letters = address
          .split('@')[0]
          .replace(/[^a-z]/gi, '')
          .slice(0, 3)
          .toUpperCase()

        formatted = address
      } else if (phoneNumber) {
        let match = phoneNumber.match(/\d{3}-\d{4}/)

        letters = match ? match[0] : phoneNumber
        formatted = phoneNumber
      }
    }
    return { letters, formatted }
  }

  async getDefaultDescription({ message, conversation, conversationId } = {}) {
    let link = ''
    let description = []

    if (!message) {
      if (!conversation && conversationId) {
        conversation = await this.fetchConversation(conversationId, ['latest_message', 'link'])
      }

      if (conversation) {
        message = conversation.latest_message
        link = conversation.link
      }
    }

    if (message) {
      link || (link = message.link)
      description.push(this.getMessageContent(message))
    }

    if (!link) {
      if (conversation && conversation.link) {
        link = conversation.link
      } else if (conversationId) {
        link = (await this.fetchConversation(conversationId, ['link'])).link
      }
    }

    if (link) {
      description.push(`\n\n--\nSource: ${link}`)
    }

    return description.join('')
  }

  getMessageContent(message) {
    let content = ['----------\n']

    const formatFields = (fields) => {
      return fields
        .map((field) => {
          let { formatted } = this.formatContact({
            name: field.name,
            address: field.address,
          })

          if (field.username) {
            if (formatted) {
              formatted += ` (${field.username})`
            } else {
              formatted = field.username
            }
          }

          return formatted
        })
        .filter(Boolean)
        .join(', ')
    }

    if (message?.from_field) {
      content.push(`From: ${formatFields([message.from_field])}\n`)
    }

    if (message?.to_fields && message.to_fields.length) {
      content.push(`To: ${formatFields(message.to_fields)}\n`)
    }

    if (message?.cc_fields && message.cc_fields.length) {
      content.push(`Cc: ${formatFields(message.cc_fields)}\n`)
    }

    if (message?.bcc_fields && message.bcc_fields.length) {
      content.push(`Bcc: ${formatFields(message.bcc_fields)}\n`)
    }

    if (message?.delivered_at) {
      content.push(
        `Date: ${new Date(message.delivered_at * 1000).toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}\n`,
      )
    }

    if (message?.subject) {
      content.push(`Subject: ${message.subject}\n`)
    }

    content.push('----------\n\n')
    content.push(message?.textContent)

    return content.join('')
  }

  pad(n, width, fill) {
    n = n.toString()
    fill = fill.toString()

    if (n.length >= width) return n
    return new Array(width - n.length + 1).join(fill) + n
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase()
  }

  underscore(string) {
    string = string || ''
    string = string.toString() // might be a number
    string = string.trim()
    string = string.replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
    string = string.replace(/[-\s]+/g, '_').toLowerCase()
    return string
  }

  humanize(string, { capitalize = true } = {}) {
    string = string || ''
    string = string.toString() // might be a number
    string = string.trim()
    string = this.underscore(string)
    string = string.replace(/[\W_]+/g, ' ')
    if (capitalize) {
      string = this.capitalize(string)
    }
    return string
  }

  parseCurrency(string) {
    string = String(string)

    // Remove “$”, commas, spaces, etc.
    if (string) {
      return string.replace(/[^\d\.]/g, '')
    } else {
      return string
    }
  }

  formatCurrency(currency = 'USD', amount = 0) {
    return new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: currency,
    }).format(amount)
  }

  classNames(classes) {
    let classNames = []

    for (let k in classes) {
      if (classes[k]) {
        classNames.push(k)
      }
    }

    return classNames.join(' ')
  }

  sortBy(items, key, { desc, prioritize } = {}) {
    if (!items || !items.length) return

    items.sort((a, b) => {
      let aValue = a[key] || ''
      let bValue = b[key] || ''
      let sortValue = 0

      if (prioritize) {
        if (aValue == prioritize) {
          return -1
        } else if (bValue == prioritize) {
          return 1
        }
      }

      if (desc) {
        sortValue = bValue.localeCompare(aValue)
      } else {
        sortValue = aValue.localeCompare(bValue)
      }

      return sortValue
    })
  }

  // Store
  storeSync(k, v) {
    let ls = window.localStorage

    if (this.id) {
      k = `${this.id}-${k}`
    }

    if (v != undefined) {
      ls[k] = JSON.stringify(v)
    } else {
      let value = ls[k]
      if (value) {
        return JSON.parse(value)
      }
    }
  }

  storeGet(key) {
    const promise = new Promise((resolve, reject) => {
      const data = { key }

      this.sendMessage('localStorageGet', data, {
        promise: { resolve, reject },
      })
    })

    return promise
  }

  storeSet(key, value) {
    const data = { key, value }

    this.sendMessage('localStorageSet', data)
  }

  // XHR
  get(opts = {}) {
    opts.method = 'get'
    return this.request(opts)
  }

  post(opts = {}) {
    opts.method = 'post'
    return this.request(opts)
  }

  patch(opts = {}) {
    opts.method = 'patch'
    return this.request(opts)
  }

  delete(opts = {}) {
    opts.method = 'delete'
    return this.request(opts)
  }

  request(opts) {
    const options = opts.options || {}
    delete opts.options

    let promise = new Promise((resolve, reject) => {
      this.sendMessage('request', opts, {
        promise: { resolve, reject },
      })
    })

    if (options.catchError !== false) {
      promise.catch((result) => {
        let message =
          result && result.response && result.response.error && result.response.error.message

        if (message) {
          const errorOpts = options.errorOpts || {}
          errorOpts.message = message

          if (errorOpts.closeForm === undefined) {
            errorOpts.closeForm =
              opts.method == 'post' ||
              opts.method == 'patch' ||
              opts.method == 'delete' ||
              message == 'Your access has been revoked.'
          }

          this.alert(errorOpts)
        }
      })
    }

    return promise
  }

  // Messaging
  async fetchConversation(...args) {
    return this.fetch('Conversation', ...args)
  }

  fetchMessage(...args) {
    return this.fetch('Message', ...args)
  }

  fetchUser(...args) {
    return this.fetch('User', ...args)
  }

  fetchLabel(...args) {
    return this.fetch('Label', ...args)
  }

  fetchOrganization(...args) {
    return this.fetch('Organization', ...args)
  }

  fetchIntegration() {
    return this.fetch('Integration').then((integrations) => integrations[0])
  }

  fetch(resourceName, id, attributes) {
    let promise = new Promise((resolve, reject) => {
      this.sendMessage('fetch', { resourceName, id, attributes }, { promise: { resolve, reject } })
    })

    return promise
  }

  respondToMessage(messageID, data) {
    data.messageID = messageID
    this.sendMessage('response', data)
  }

  sendMessage(event, data, opts = {}) {
    if (!this.id) {
      return this.enqueueEvent([event, data, opts])
    }

    let messageID = this.messageID++
    let params = { event, data, messageID, id: this.id }

    if (opts.promise) {
      this.messageCallbacks[messageID] = opts.promise
    }

    parent.postMessage(params, '*')
  }

  handleMessage(e) {
    if (e.source == window || !e.data) return
    let { trigger, event, data } = e.data

    if (trigger) {
      return this.trigger(trigger, data)
    }

    if (event) {
      if (event.startsWith('message_response:')) {
        let messageID = parseInt(event.split(':')[1])
        let messageCallback = this.messageCallbacks[messageID]

        if (!messageCallback) {
          return
        }

        if ('resolve' in data) {
          messageCallback.resolve(data.resolve)
        } else if ('reject' in data) {
          messageCallback.reject(data.reject)
        }

        return
      }

      if (event.startsWith('action:')) {
        let { messageID } = e.data
        let index = parseInt(event.split(':')[1])
        let actionCallback = this.actionCallbacks[index]
        return actionCallback(data, messageID)
      }

      if (event.startsWith('stream:')) {
        let streamId = event.split(':')[1]
        let callbacks = this.streamCallbacks[streamId]
        if (callbacks) {
          for (let callback of this.streamCallbacks[streamId]) {
            callback(data, streamId)
          }
        }
      }

      if (this[event]) {
        this[event](data)
      }
    }
  }

  convertMarkdownToHtml(markdown) {
    if (!markdown) return ''
    try {
      // Using micromark with strict options
      return micromark(markdown, {
        allowDangerousHtml: false,
        allowDangerousProtocol: false,
      })
    } catch (error) {
      return markdown
    }
  }
}

const ACTION_CONTEXTS = ['conversations', 'conversation', 'message', 'comment', 'swipe']

const MONTHS = [
  { long: 'January', short: 'Jan' },
  { long: 'February', short: 'Feb' },
  { long: 'March', short: 'Mar' },
  { long: 'April', short: 'Apr' },
  { long: 'May', short: 'May' },
  { long: 'June', short: 'Jun' },
  { long: 'July', short: 'Jul' },
  { long: 'August', short: 'Aug' },
  { long: 'September', short: 'Sep' },
  { long: 'October', short: 'Oct' },
  { long: 'November', short: 'Nov' },
  { long: 'December', short: 'Dec' },
]

// https://materialdesignicons.com
const SVGS = {
  'account-circle': {
    viewBox: '0 0 24 24',
    svg:
      '<path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />',
  },
  'chevron-down': {
    viewBox: '0 0 24 24',
    svg: '<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />',
  },
  'circle': {
    viewBox: '0 0 24 24',
    svg: '<circle cx="12" cy="12" r="11"></circle>',
  },
  'comment': {
    viewBox: '0 0 24 24',
    svg:
      '<path d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,16H6L4,18V4H20" />',
  },
  'external': {
    viewBox: '0 0 24 24',
    svg:
      '<path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />',
  },
  'menu-right': {
    viewBox: '0 0 24 24',
    svg: '<path d="M10,17L15,12L10,7V17Z" />',
  },
  'cog': {
    viewBox: '0 0 24 24',
    svg:
      '<path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />',
  },
  'search': {
    viewBox: '0 0 24 24',
    svg:
      '<path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />',
  },
}

window.Missive = new MissiveJS()
window.Missive.SVGS = SVGS

window.addEventListener('message', window.Missive.handleMessage)
parent.postMessage({ event: 'ready' }, '*')
